<div>
    <div [innerHTML]="data.message" style="padding: 12px 24px; min-height: 68px;"></div>

    <div style="float: right; margin: 0 12px">
        <button mat-flat-button (click)="closeAction()" color="primary">
            <mat-icon>cancel</mat-icon>
            Close
        </button>
    </div>
</div>
