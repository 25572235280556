<mat-tree [dataSource]="dataSourceData" [treeControl]="treeControl" [class.menuMode]="menuMode">
    <mat-tree-node *matTreeNodeDef="let node;" matTreeNodePadding 
    [class.selected]="node._selected ? true : (node.id == nodeId)" 
    [matTreeNodePaddingIndent] = "treeNodePadding">
        <button mat-icon-button matTreeNodeToggle
            [class.hidden]="!node._expandable"
            [attr.aria-label]="'Toggle ' + node.name"
            (click)="expandAsset(node)">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <div class="left-column">
            <span class="assetIcon">
                <img _ngcontent-hsc-c122="" [src]="node._icon">
            </span>
        </div>
        <div class="right-column">
            <span class="parentNode" (click)="selectAsset(node)">{{node.name}}</span>
            <span class="edt" [innerHTML]="node | edtcode"></span>
        </div>
    </mat-tree-node>
</mat-tree>
