import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TreeComponent, TreeNode } from 'src/app/pages/general/asset-navigator/components/tree/tree.component';
import { UserService } from 'src/app/services/user.service';
import { DTO } from 'src/dto/dto';
import DOMPurify from 'dompurify';

export type ConfirmationDialogArguments = {
    title: string,
    message: string,
    isCritical: boolean,
    isTree: boolean,
    asset: DTO[] // Is this an array or not?
    getInput: boolean
}

@Component({
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    imports: [
        MatButtonModule,
        MatIconModule,
        FormsModule,
        TreeComponent,
        MatDialogModule
    ],
    standalone: true
})
export class ConfirmationComponent {

    public email: string = "";
    public node: DTO;
    public nodeId: number;
    public userInput: string = "";

    public renderedTitle: string = "";
    public renderedMessage: string = "";

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogArguments,
        public dialogRef: MatDialogRef<any>,
        public userService: UserService
    ) {
        this.renderedTitle = DOMPurify.sanitize(data.title);
        this.renderedMessage = DOMPurify.sanitize(data.message);
    }

    confirmAction() {
        if (this.data.getInput)
            return this.dialogRef.close(this.userInput);

        if (!this.data.isCritical || this.email == this.userService.email)
            this.dialogRef.close(true);
    }

    cancelAction() {
        this.dialogRef.close(false);
    }

    selectParent(trail: TreeNode[]) {
        this.node = trail.find(x => x._selected);
        this.nodeId = this.node?.id;
    }

    confirmMove() {
        this.dialogRef.close(this.node);
    }
}
