export const InitDraggableDialogs = async () => {
    await import('@interactjs/auto-start');
    await import('@interactjs/actions/drag');
    await import('@interactjs/actions/resize');
    await import('@interactjs/modifiers');
    const interact = await import('@interactjs/interact');

    const draggable = interact.default('.dialog-draggable');
    draggable.styleCursor(false);

    const $pos = window['$pos'] = Symbol("position");
    draggable.draggable({
        allowFrom: '.titlebar',
        // ! Edge(Chromium) rasters things inaccurately, so we need to use left/top instead of
        // a transform. (Feb 13, 2023)
        onstart: (evt) => {
            evt.currentTarget.style.cursor = "grabbing";
            const bounds = evt.currentTarget.getBoundingClientRect();

            evt.currentTarget[$pos] = {
                x: bounds.x,
                y: bounds.y,
                w: bounds.width,
                h: bounds.height
            };
        },
        onmove: (evt) => {
            const el = evt.currentTarget as HTMLElement;
            const pos = el[$pos];

            el.style.left = `${pos.x += evt.dx}px`;
            el.style.top = `${pos.y += evt.dy}px`;
        },
        onend: (evt) => {
            evt.currentTarget.style.cursor = "";
        }
    });


    const resizable = interact.default('.dialog-resizable');
    resizable.styleCursor(true);
    resizable.resizable({
        margin: 5,
        edges: { top: true, left: true, bottom: true, right: true },
        onstart: (evt) => {
            const bounds = evt.currentTarget.getBoundingClientRect();

            evt.currentTarget[$pos] = {
                x: bounds.x,
                y: bounds.y,
                w: bounds.width,
                h: bounds.height
            };
        },
        onmove: evt => {
            const el = evt.currentTarget;
            const pos = el[$pos];

            el.style.height = (pos.h = evt.rect.height) + "px";
            el.style.width = (pos.w = evt.rect.width) + "px";

            el.style.left = (pos.x = evt.rect.left) + 'px';
            el.style.top = (pos.y = evt.rect.top) + 'px';
        }
    });
}
