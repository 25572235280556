<div [innerHTML]="renderedTitle" class="titleMessage" [class.treeTitleMessage]="data.isTree" [class.nonTreeTitleMessage]="!data.isTree"></div>
<div [innerHTML]="renderedMessage" class="confirmationMessage"></div>

@if (data.isCritical) {
    <div class="criticalMessage">
        <p>Please type in your email address to confirm:</p>
        <input
            type="email"
            autocomplete="lets-not-do-this"
            [(ngModel)]="email"
            (keydown)="
                !(email != userService.email) && $event.key == 'Enter'
                ? confirmAction()
                : null
            "
        >
    </div>
}

<mat-dialog-content>
    @if (data.isTree) {
        <div>
            <app-tree [trail]="data.asset" (selectChange)="selectParent($event)" [nodeId]="nodeId" [menuMode]="true" [isMove]="true"></app-tree>
        </div>
    }
    @else if (data.getInput) {
        <div style="padding: 0 24px">
            <input
                style="width: 100%"
                type="text"
                [(ngModel)]="userInput"
                (keydown)="
                    $event.key == 'Enter'
                    ? confirmAction()
                    : null
                "
            />
        </div>
    }
</mat-dialog-content>

<div style="float: right; margin: 6px">
    @if (data.isTree) {
        <button mat-flat-button (click)="confirmMove()" color="primary">
            <mat-icon>moving</mat-icon>
            Move
        </button>
    }
    @else {
        <button mat-flat-button (click)="confirmAction()" color="primary" [disabled]="data.isCritical && email != userService.email">
            <mat-icon>check</mat-icon>
            Yes
        </button>
    }

    <button mat-flat-button (click)="cancelAction()" color="warn">
        <mat-icon>cancel</mat-icon>
        No
    </button>
</div>
